// TradingViewWidget.js

import React, { useEffect, useLayoutEffect, useRef } from "react";
import Logo from "./logo.png";

let tvScriptLoadingPromise;

export default function Graph() {
  const onLoadScriptRef = useRef();

  useLayoutEffect(() => {
    let properties = window.location.search.slice(1).split(":::");
    // console.log(properties);
    if (true) {
      onLoadScriptRef.current = createWidget;
      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement("script");
          script.id = "tradingview-widget-loading-script";
          script.src = "https://s3.tradingview.com/tv.js";
          script.type = "text/javascript";
          script.onload = resolve;
          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(
        () => onLoadScriptRef.current && onLoadScriptRef.current()
      );

      return () => (onLoadScriptRef.current = null);

      function createWidget() {
        if (
          document.getElementById("tradingview_b1474") &&
          "TradingView" in window
        ) {
          new window.TradingView.widget({
            autosize: true,
            // symbol: "NASDAQ:" + properties[0].toUpperCase(),
            symbol:
              properties[0].toUpperCase() + ":" + properties[1].toUpperCase(),
            interval: "1",
            timezone: "Etc/UTC",
            //   theme: "light",
            theme: properties[2],
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            range: "60M",
            enable_publishing: false,
            hide_side_toolbar: false,
            allow_symbol_change: false,
            //   details: true,
            //   hotlist: true,
            //   calendar: true,
            save_image: false,
            container_id: "tradingview_b1474",
          });
        }
      }
    }
  }, []);

  return (
    <div className="tradingview-widget-container" style={{ height: "100vh" }}>
      {/* 1 */}
      <div id="tradingview_b1474" style={{ height: "100vh" }} />
      <div className="img_holder">
        <img src={Logo} />
      </div>
      {/* <div className="tradingview-widget-copyright"> */}
      {/* <a
          href="https://www.tradingview.com/symbols/NASDAQ-MSFT/"
          rel="noopener"
          target="_blank"
          >
          <span className="blue-text">MSFT stock chart</span>
          </a>{" "}
        by TradingView */}
      {/* </div> */}
    </div>
  );
}
